// Projects data.
// Note: Here we are getting data from a js file, but in a different project I'll be fetching these projects from some srt of APIs.

const projects = [
  {
    id: 1,
    title: "Task Manager",
    category: "Web Application",
    img: require("@/assets/images/web-project-taskmanager.png"),
  },
  {
    id: 2,
    title: "Finance App",
    category: "Web Application",
    img: require("@/assets/images/web-project-finance.png"),
  },
  {
    id: 3,
    title: "Circle Design Agency",
    category: "Website",
    img: require("@/assets/images/web-project-circledesign.png"),
  },
  {
    id: 4,
    title: "UNPlayer Marketplace",
    category: "UX/UI Design",
    img: require("@/assets/images/uxui-project-unplayer.png"),
  },
  {
    id: 5,
    title: "Quasar Chat",
    category: "Web Application",
    img: require("@/assets/images/web-project-quasarchat.png"),
  },
  {
    id: 6,
    title: "Advantix Hosting",
    category: "Website",
    img: require("@/assets/images/web-project-advantix.png"),
  },
  {
    id: 7,
    title: "Patuju",
    category: "Website",
    img: require("@/assets/images/web-project-patuju.png"),
  },
  {
    id: 8,
    title: "Support Panel Hub",
    category: "Web Application",
    img: require("@/assets/images/web-project-support.png"),
  },
  {
    id: 9,
    title: "Rasca y Gana",
    category: "Web Application",
    img: require("@/assets/images/web-project-rasca.png"),
  },
];

export default projects;
